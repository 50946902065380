import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

const StyledLabel = styled(InputLabel)`
  && {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
  }
`

export default withTheme()(StyledLabel)
