import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AdminAuthConsumer } from '../Auth/AdminAuthProvider'
import logoVertical from '../../assets/images/logo_vertical_white.png'
import { MODE } from '../../config/app'

class Header extends React.Component {
  constructor () {
    super()

    this.state = {
      anchorEl: null
    }

    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleProfileMenuOpen (event) {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleMenuClose () {
    this.setState({ anchorEl: null })
  };

  // Not using an arrow function here because extra logic might be needed in the future
  handleLogout (logout) {
    logout()
  };

  render () {
    const {
      anchorEl
    } = this.state
    const { classes } = this.props
    const isMenuOpen = Boolean(anchorEl)

    return (
      <AdminAuthConsumer>
        {({ user, logout }) => {
          const { firstname, lastname, name } = user || {};
          return (
            <div className={classes.root}>
              <CssBaseline />
              <AppBar position='static'>
                <Toolbar>
                  <img
                    className={classes.logo}
                    src={logoVertical}
                    alt='Freeplay Logo'
                  />
                  <div className={classes.grow} />
                  <IconButton
                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                    aria-haspopup='true'
                    onClick={this.handleProfileMenuOpen}
                    color='inherit'
                  >
                    <AccountCircle />
                  </IconButton>
                  <Typography className={classes.title} variant='h5' color='inherit' noWrap>
                    {MODE === 'admin' ? `${firstname} ${lastname}` : name}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
              >
                <MenuItem onClick={() => this.handleLogout(logout)}>Log out</MenuItem>
              </Menu>
            </div>
          )
        }}
      </AdminAuthConsumer>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontSize: '0.9em',
    fontWeight: 'bold'
  },
  logo: {
    maxWidth: '200px'
  }
})

Header.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Header)
