export const accessFlags = {
  root: '/accessFlags',
  list: '/accessFlags/list',
  new: '/accessFlags/new',
  edit: '/accessFlags/:id/edit',
};
export const activities = {
  root: '/activities',
  list: '/activities/list',
  new: '/activities/new',
  edit: '/activities/:id/edit',
};
export const checkins = {
  root: '/checkins',
  list: '/checkins/list',
  new: '/checkins/new',
  edit: '/checkins/:id/edit'
};
export const checkinsReport = {
  root: '/checkinsReport',
};
export const corporatePlans = {
  edit: '/corporatePlans/:id/edit',
  list: '/corporatePlans/list',
  new: '/corporatePlans/new',
  root: '/corporatePlans',
};
export const coupons = {
  root: '/coupons',
  list: '/coupons/list',
};
export const login = '/login';
export const moods = {
  root: '/moods',
  list: '/moods/list',
  new: '/moods/new',
  edit: '/moods/edit/:id',
};
export const partners = {
  root: '/partners',
  list: '/partners/list',
  new: '/partners/new',
  edit: '/partners/edit/:id'
};
export const partnerGroups = {
  edit: '/partnerGroups/:id/edit',
  list: '/partnerGroups',
  map: '/partnerGroups/:id/map',
  new: '/partnerGroups/new',
  view: '/partnerGroups/:id',
};
export const instructors = {
  root: '/instructors',
  list: '/instructors/list',
  view: '/instructors/:id',
};
export const reportedPosts = {
  root: '/reportedPosts',
  list: '/reportedPosts/list',
  view: '/reportedPosts/:id',
};
export const users = {
  root: '/users',
  list: '/users/list',
  list2: '/usersList',
  edit: '/users/edit/:id'
};

export default {
  accessFlags,
  activities,
  checkins,
  checkinsReport,
  corporatePlans,
  coupons,
  instructors,
  login,
  moods,
  partners,
  partnerGroups,
  reportedPosts,
  users,
}
