import axios from 'axios'

axios.interceptors.request.use(async function (config) {
  const token = localStorage.getItem('session')
  config.baseURL = process.env.REACT_APP_API_URL
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

axios.interceptors.response.use(function (response) {
  return response
}, function (e) {
  if (e.response.status === 403) localStorage.clear()
  return Promise.reject(e)
})

export const loginAdmin = async function (email, password) {
  const { data: { token } } = await axios.post('/login', { email, password })
  return token
}

export const loginPartner = async function (email, password) {
  const { data: { token } } = await axios.post('/partner/login', { email, password })
  return token
}

export const registerPartner = async function (email, password) {
  const { data: { token } } = await axios.post('/partner/register', { email, password })
  return token
}

export const partnerIsRegistered = async function (email) {
  const { data: { isRegistered } } = await axios.get('/partner/is_registered', { params: { email } })
  return isRegistered
}

export const getLoggedInPartnerInfo = async function () {
  const { data: user } = await axios.get('/partner/me')
  return user
}

export const getCheckins = async function () {
  const { data: res } = await axios.get('/partner/checkins')
  return res
}

export const getGyms = async function () {
  const { data: res } = await axios.get('/admin/gyms?status=all')
  return res
}

export const showGym = async function (id) {
  const { data } = await axios.get(`/admin/gyms/${id}`)
  return data
}

export const storeGym = async function (gymData) {
  const { data } = await axios.post('/admin/gyms', gymData)
  return data
}

export const updateGym = async function (gymData, id) {
  const { data } = await axios.put(`/admin/gyms/${id}`, gymData)
  return data
}

export const getUsers = async function (offset, results, term) {
  const { data: res } = await axios.get('/admin/users', { params: { offset, results, term } })
  return res
}

export const showUser = async function (id) {
  const { data } = await axios.get(`/admin/users/${id}`)
  return data
}

export const updateUser = async function (userData, id) {
  const { data } = await axios.put(`/admin/users/${id}`, userData)
  return data
}

export const deleteNumber = async function (phoneNumber) {
  const { data } = await axios.delete(`admin/verified_number/${phoneNumber}`)
  return data
}

export const requestPhoneCode = async function (number, login = false) {
  const { data: res } = await axios.post('/phone/request_verification', { number, login })
  return res
}

export const softVerification = async function (number, code) {
  const res = await axios.post('/phone/soft_verification', { number, code })
  return res
}

export const getActivities = async function (id) {
  const { data } = await axios.get(`/activities`)
  return data
}

export const getAmenities = async function (id) {
  const { data } = await axios.get(`/amenities`)
  return data
}

export const getSelectedAmenities = async function (id) {
  const { data } = await axios.get(`/gyms/amenities/${id}`)
  return data
}

export const sendResetLink = async function (email) {
  const { data } = await axios.post('/password/reset_request', null, { params: { email } })
  return data
}

export const getLoggedInUserInfo = async function () {
  const { data: user } = await axios.get('/me')
  return user
}

export const getClasses = async function ({gymid, within, from, to, activities, search, skip, latitude, longitude, dropInFilter = false, primeFilter = false, favFilter = false, limit}) {
  const { data: res } = await axios.get('/partner/classes', { params: { gymid, within, lat: latitude, long: longitude, from, to, activities, q: search, skip, dropInFilter, primeFilter, favFilter, limit } })
  return res
}

export const addTribeUser = async function (userId, tribeId) {
  const res = await axios.put(`/admin/tribes/${tribeId}/join/${userId}`)
  return res
}

export const deleteTribeUser = async function (userId, tribeId) {
  const { data } = await axios.delete(`/admin/tribes/${tribeId}/member/${userId}`)
  return data
}

export const getFeed = async function () {
  const { data } = await axios.get('/admin/feed')
  return data
}

export const showCheckin = async function (id) {
  const { data } = await axios.get(`/admin/checkins/${id}`)
  return data
}

export const updateCheckin = async function (checkin) {
  const { data } = await axios.put(`/admin/checkin`, checkin)
  return data
}

export const createCheckin = async function (checkin) {
  const { data } = await axios.post(`/admin/checkin`, checkin)
  return data
}
