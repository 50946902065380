import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import {
  MainButton,
  StyledLabel,
  StyledInput,
  StyledSnackbar
} from '../../components/UI'
import { AdminAuthConsumer } from '../../components/Auth/AdminAuthProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import RequestPasswordReset from './RequestPasswordReset';
import logoSquare from '../../assets/images/logo_square.png'
import routes from '../../config/admin-routes'

class LoginForm extends React.Component {
  constructor () {
    super()
    this.state = {
      username: null,
      password: null,
      loading: false,
      snackbarOpen: false,
      snackbarResponse: null
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  async handleSubmit (event, login) {
    event.preventDefault()
    this.setState({ loading: true })
    try {
      const { username, password } = this.state
      await login(username, password)
    } catch (e) {
      this.setState({
        loading: false,
        snackbarOpen: true,
        snackbarResponse: e.message
      })
    }
  }

  render () {
    const { classes } = this.props
    const { loading, snackbarOpen, snackbarResponse } = this.state
    return (
      <div>
        <StyledSnackbar
          variant='error'
          open={snackbarOpen}
          message={snackbarResponse}
          onClose={() => this.setState({ snackbarOpen: false })}
        />
        <main className={classes.main}>
          <CssBaseline />
          <div className={classes.container}>
            <img
              className={classes.logo}
              src={logoSquare}
              alt='Freeplay Logo'
            />
            <AdminAuthConsumer>
              {({ user, login }) => (
                user && user.isAdmin
                  ? <Redirect to={routes.partners.list} />
                  : (
                    <form
                      className={classes.form}
                      onSubmit={event => this.handleSubmit(event, login)}
                    >
                      <FormControl margin='normal' required fullWidth>
                        <StyledLabel shrink htmlFor='email'>
                          email
                        </StyledLabel>
                        <StyledInput
                          id='email'
                          name='username'
                          placeholder='Email'
                          type='email'
                          onChange={this.handleChange}
                        />
                      </FormControl>
                      <FormControl margin='normal' required fullWidth>
                        <StyledLabel shrink htmlFor='password'>
                          password
                        </StyledLabel>
                        <StyledInput
                          id='password'
                          name='password'
                          placeholder='Password'
                          type='password'
                          onChange={this.handleChange}
                        />
                      </FormControl>
                      <RequestPasswordReset email={this.state.username}/>
                      <div className={classes.loginBtnContainer}>
                        {
                          loading
                            ? (<CircularProgress />)
                            : (
                              <MainButton
                                type='submit'
                                variant='contained'
                                color='primary'
                              >Sign In</MainButton>
                            )
                        }
                      </div>
                    </form>
                  )
              )}
            </AdminAuthConsumer>
          </div>
        </main>
      </div>
    )
  }
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(500 + theme.spacing.unit * 2)]: {
      width: 450,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    textAlign: 'center'
  },
  container: {
    borderRadius: 20,
    boxShadow: '0px 0px 40px 10px rgba(0,0,0,0.05)',
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`
  },
  logo: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    maxWidth: '145px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  loginBtnContainer: {
    marginTop: theme.spacing.unit * 2
  }
})

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(LoginForm)
