import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { get, isNil } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles';
import logoSquare from '../../assets/images/logo_square.png'
import {
  MainButton,
  StyledLabel,
  StyledInput,
} from '../../components/UI'

const resetPasswordMutation = gql`
  mutation Login_resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(500 + theme.spacing.unit * 2)]: {
      width: 450,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    textAlign: 'center'
  },
  container: {
    borderRadius: 20,
    boxShadow: '0px 0px 40px 10px rgba(0,0,0,0.05)',
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`
  },
  logo: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    maxWidth: '145px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  loginBtnContainer: {
    marginTop: theme.spacing.unit * 2
  }
});

export function RequestPasswordReset({ classes, email }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [reset, { error, loading: isLoading }] = useMutation(resetPasswordMutation, {
    onCompleted: (response) => {
      if (!isNil(response) && response.resetPassword) {
        window.location.href = '/login';
      }
    },
  });

  useEffect(() => {
    const message = get(error, 'message', null);
    if (!isNil(message)) {
      if (message.includes('Invalid password')) {
        alert('Invalid password');
      } else if (message.includes('User not found') || message.includes('Token has expired')) {
        alert('Token has expired');
      } else {
        alert('Something went wrong');
      }
    }
  }, [error]);
  return (
    <div>
      <main className={classes.main}>
        <CssBaseline />
        <div className={classes.container}>
          <img
            className={classes.logo}
            src={logoSquare}
            alt='Freeplay Logo'
          />
          <form
            className={classes.form}
            onSubmit={(event) => {
              event.preventDefault();
              const params = new URLSearchParams(window.location.search);
              const email = params.get('email');
              const token = params.get('token');
              if (password === confirmPassword) {
                reset({
                  variables: {
                    input: {
                      email,
                      password,
                      token,
                    },
                  },
                });
              } else {
                alert('Passwords do not match');
              }
            }}
          >
            <FormControl margin='normal' required fullWidth>
              <StyledLabel shrink htmlFor='password'>
                password
              </StyledLabel>
              <StyledInput
                id='password'
                name='password'
                placeholder='Password'
                type='password'
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <StyledLabel shrink htmlFor='password'>
                confirm password
              </StyledLabel>
              <StyledInput
                id='confirm-password'
                name='confirm-password'
                placeholder='Confirm password'
                type='password'
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </FormControl>
            <div className={classes.loginBtnContainer}>
              {
                isLoading
                  ? (<CircularProgress />)
                  : (
                    <MainButton
                      type='submit'
                      variant='contained'
                      color='primary'
                    >Reset</MainButton>
                  )
              }
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default withStyles(styles)(RequestPasswordReset);
