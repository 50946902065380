import { createMuiTheme } from '@material-ui/core/styles'

const baseTypography = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(',')

const fontStyleReset = {
  textTransform: 'capitalize'
}

const baseSpacing = 24

export default createMuiTheme({
  palette: {
    primary: {
      main: '#007fb2'
    },
    secondary: {
      main: '#EB3A70'
    }
  },
  spacing: {
    unit: baseSpacing
  },
  customColors: {
    grey: '#F7F7F7',
    white: '#FFFFFF'
  },
  shadows: Array(25).fill('none'),
  typography: {
    root: {
      fontSize: 12,
      // Use the system font instead of the default Roboto font.
      fontWeightLight: 500,
      fontWeightRegular: 500,
      fontWeightMedium: 500,
      fontFamily: baseTypography
    }
  },
  overrides: {
    MuiLink: {
      root: {
        fontFamily: baseTypography,
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 'bold'
      }
    },
    MuiFormControlLabel: {
      label: fontStyleReset
    },
    MuiFormHelperText: {
      root: fontStyleReset
    },
    MuiFormLabel: {
      root: fontStyleReset
    },
    MuiTypography: {
      h6: {
        textTransform: 'uppercase',
        marginBottom: `${baseSpacing}px`,
        fontSize: '14px'
      }
    }
  }
})
