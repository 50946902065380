import React from 'react'
import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'

const StyledInput = styled(props => (
  <Input disableUnderline {...props} />
))(props => `
  && {
    border-radius: 10px;
    position: relative;
    background-color: ${props.theme.palette.common.white};
    border: 1px solid #ced4da;
    font-size: 1rem;
    padding: 10px 12px;
    transition: ${props.theme.transitions.create(['border-color', 'box-shadow'])};
    position: relative;

    + label {
      margin-top: ${props.theme.spacing.unit};
    }

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
  }
`)

export default withTheme()(StyledInput)
