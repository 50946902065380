import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';

export const graphQLApiPath = '/graphql';

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      gymGroup: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'GymGroup', id }),
      gym: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'Gym', id }),
    },
  },
});
cache.writeData({
  data: {},
});

const token = localStorage.getItem('session')

const batchLink = new BatchHttpLink({
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
  },
  uri: `${process.env.REACT_APP_API_URL}${graphQLApiPath}`,
});
const wsLink = new WebSocketLink({
  options: {
    connectionParams: {
      authToken: token,
    },
    reconnect: true
  },
  uri: `${process.env.REACT_APP_WS_URL}${graphQLApiPath}`,
});

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
    mutate: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  },
  link: split(
    // https://www.apollographql.com/docs/react/data/subscriptions/
    ({ query }) => {
      const definition = getMainDefinition(query);

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    batchLink,
  ),
  name: 'admin',
  resolvers: {},
});
