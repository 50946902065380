import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const MainButton = styled(Button)(props => `
  && {
    border-radius: 20px;
    min-width: 100px;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
  }
`)

export default withTheme()(MainButton)
