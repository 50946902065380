import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { get, isEmpty, isNil } from 'lodash';
import { useMutation } from '@apollo/react-hooks';

const passwordResetRequestMutation = gql`
  mutation Login_requestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;

export function RequestPasswordReset({ email }) {
  const [reset, { error }] = useMutation(passwordResetRequestMutation, {
    onCompleted: (response) => {
      if (!isNil(response) && response.requestPasswordReset) {
        alert('Check email to reset password');
      }
    },
  });

  useEffect(() => {
    const message = get(error, 'message', null);
    if (!isNil(message)) {
      if (message.includes('Too many requests made')) {
        alert('Too many requests have been made');
      } else if (message.includes('User not found')) {
        alert('Please enter a valid email address');
      } else {
        alert('Something went wrong');
      }
    }
  }, [error]);

  return (
    <div
      onClick={() => {
        if (isNil(email) || isEmpty(email)) {
          alert('Please enter an email');
        } else {
          reset({
            variables: {
              input: {
                email,
              },
            },
          });
        }
      }}
      style={{ color: 'blue', cursor: 'pointer', textAlign: 'left' }}
    >
      Reset password
    </div>
  );
}

export default RequestPasswordReset;
