import React, { lazy, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress'
import { ApolloProvider } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { apolloClient } from './apolloClient';
import AdminProtectedRoute from './components/Auth/AdminProtectedRoute';
import {
  Header,
  NavLeft
} from './components/UI';
import {
  accessFlags,
  activities,
  checkins,
  checkinsReport,
  corporatePlans,
  coupons,
  instructors,
  moods,
  partners,
  partnerGroups,
  reportedPosts,
  users,
} from './config/admin-routes';
const AccessFlags = lazy(() => import('./modules/accessFlags'));
const Activities = lazy(() => import('./modules/activities'));
const Checkins = lazy(() => import('./modules/AdminCheckins'));
const CheckinsReport = lazy(() => import('./modules/AdminCheckinsReport'));
const CorporatePlans = lazy(() => import('./pages/corporatePlans'));
const Coupons = lazy(() => import('./modules/coupons'));
const EditCorporatePlan = lazy(() => import('./pages/editCorporatePlan'));
const Instructors = lazy(() => import('./modules/instructors'));
const Moods = lazy(() => import('./modules/moods'));
const NewCorporatePlan = lazy(() => import('./pages/newCorporatePlan'));
const Partners = lazy(() => import('./modules/Partners'));
const PartnerGroups = lazy(() => import('./modules/partnerGroups'));
const ReportedPosts = lazy(() => import('./modules/reportedPosts'));
const Users = lazy(() => import('./modules/Users'));
const UsersList = lazy(() => import('./pages/users'));

function AdminLayout({ classes }) {
  return (
    <Suspense fallback={<CircularProgress style={{marginLeft: '50%', marginTop: '50%'}} />}>
      <ApolloProvider client={apolloClient}>
        <div className={classes.container}>
          <Header />
          <Grid container>
            <Grid item xs={2} className={classes.leftNavWrapper}>
              <NavLeft />
            </Grid>
            <Grid item xs={10} className={classes.mainWrapper}>
              <Switch>
                <AdminProtectedRoute path={accessFlags.root} component={AccessFlags} />
                <AdminProtectedRoute path={activities.root} component={Activities} />
                <AdminProtectedRoute path={checkinsReport.root} component={CheckinsReport} />
                <AdminProtectedRoute path={corporatePlans.edit} component={EditCorporatePlan} />
                <AdminProtectedRoute path={corporatePlans.list} component={CorporatePlans} />
                <AdminProtectedRoute path={corporatePlans.new} component={NewCorporatePlan} />
                <AdminProtectedRoute path={partners.root} component={Partners} />
                <AdminProtectedRoute path={partnerGroups.list} component={PartnerGroups} />
                <AdminProtectedRoute path={instructors.root} component={Instructors} />
                <AdminProtectedRoute path={users.root} component={Users} />
                <AdminProtectedRoute path={users.list2} component={UsersList} />
                <AdminProtectedRoute path={checkins.root} component={Checkins} />
                <AdminProtectedRoute path={coupons.root} component={Coupons} />
                <AdminProtectedRoute path={moods.root} component={Moods} />
                <AdminProtectedRoute path={reportedPosts.root} component={ReportedPosts} />
                <Redirect from="/" to={partners.list} />
              </Switch>
            </Grid>
          </Grid>
        </div>
      </ApolloProvider>
    </Suspense>
  );
}

const styles = ({ spacing }) => ({
  container: {
    background: '#FFF',
    'min-height': '100vh',
    height: '100%',
    fontFamily: "'GothamRounded', 'Varela Round', sans-serif",
  },
  leftNavWrapper: {
    height: '100%',
    'min-height': 'calc(100vh - 64px)',
  },
  mainWrapper: {
    height: 'calc(100vh + -64px)',
    overflowY: 'auto',
  },
});

export default withStyles(styles)(AdminLayout);
