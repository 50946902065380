import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AdminAuthConsumer } from './AdminAuthProvider'
import routes from '../../config/admin-routes'

const AdminProtectedRoute = ({ component: Component, ...rest }) => (
  <AdminAuthConsumer>
    {({ user }) => (
      <Route
        render={
          props => user && user.isAdmin
            ? <Component {...props} />
            : <Redirect to={routes.login} />
        }
        {...rest}
      />
    )}
  </AdminAuthConsumer>
)
export default AdminProtectedRoute
