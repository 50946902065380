import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import routes from '../../config/admin-routes'

const styles = theme => ({
  nav: {
    background: theme.customColors.grey,
    height: '100%',
    'min-height': 'calc(100vh - 64px)',
    textDecoration: 'none',
    padding: 0
  },
  legend: {
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none'
  }
})

class NavLeft extends React.Component {
  render () {
    const { classes } = this.props
    const { accessFlags, activities, checkins, checkinsReport, coupons, moods, partners, partnerGroups, reportedPosts, users } = routes

    return (
      <List className={classes.nav}>
        <Link to={partnerGroups.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Partner Groups' />
          </ListItem>
        </Link>
        <Link to={partners.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Partners' />
          </ListItem>
        </Link>
        <a href="/instructors?approval=NOT_REVIEWED" className={classes.link}>
          <ListItem button>
            <ListItemText primary='Instructors' />
          </ListItem>
        </a>
        <Link to={users.list2} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Users' />
          </ListItem>
        </Link>
        <Link to={activities.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Activities' />
          </ListItem>
        </Link>
        <Link to={checkins.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Checkins' />
          </ListItem>
        </Link>
        <Link to={coupons.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Coupons' />
          </ListItem>
        </Link>
        <Link to={moods.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Moods' />
          </ListItem>
        </Link>
        <Link to={reportedPosts.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Reported Posts' />
          </ListItem>
        </Link>
        <Link to={accessFlags.list} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Beta Access' />
          </ListItem>
        </Link>
        <Link to={checkinsReport.root} className={classes.link}>
          <ListItem button>
            <ListItemText primary='Checkins Report' />
          </ListItem>
        </Link>
      </List>
    )
  }
}

NavLeft.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NavLeft)
