import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from 'react-apollo';
import { apolloClient } from './apolloClient';
import theme from './config/theme';
import { login } from './config/admin-routes';
import { AdminAuthProvider } from './components/Auth/AdminAuthProvider';
import AdminLoginForm from './modules/Auth/AdminLoginForm';
import PasswordReset from './pages/passwordReset';
import AdminLayout from './AdminLayout';
import * as serviceWorker from './serviceWorker';

// TODO catch 404 (at least) and other errors.

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <AdminAuthProvider>
          <CssBaseline />
          <Switch>
            <Route path={login} component={AdminLoginForm} />
            <Route path="/password_reset/" component={PasswordReset} />
            <Route path="*" component={AdminLayout}/>
          </Switch>
        </AdminAuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
