import React from 'react'
import {
  loginAdmin,
  getLoggedInUserInfo
} from '../../utils/api'

const AuthContext = React.createContext()

class AdminAuthProvider extends React.Component {
  constructor () {
    super()
    const user = localStorage.getItem('user')
    this.state = {
      token: localStorage.getItem('session') || null,
      user: JSON.parse(user)
    }
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
  }

  async login (email, password) {
    try {
      const token = await loginAdmin(email, password)
      localStorage.setItem('session', token)
      const user = await getLoggedInUserInfo()
      if (!user.isAdmin) throw new Error('User is not admin.')
      localStorage.setItem('user', JSON.stringify(user))
      this.setState({ token, user })
    } catch (e) {
      const message = e.response ? e.response.data.message : e.message
      throw new Error(`${message || 'Something went wrong. Please try again.'}`)
    }
  }

  logout () {
    localStorage.clear()
    this.setState({
      token: false,
      user: false
    })
  }

  render () {
    const { token, user } = this.state
    return (
      <AuthContext.Provider
        value={{
          token,
          user,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AdminAuthConsumer = AuthContext.Consumer
export { AdminAuthProvider, AdminAuthConsumer } // TODO: Change consumption of AdminAuthConsumer
